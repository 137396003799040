import React, { ReactElement } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useGCtx } from '../../context/providers/GlobalProvider';
import { breakPoints, useCurrentWidth } from '../../actions/ui/windowSize';
import SlideCard from '../UI/cards/card';


// Styles
import '../../styles/pages/onepage-restaurants.scss';
import 'swiper/swiper.scss';

const Restaurants = (): ReactElement => {
    const { width } = useCurrentWidth();
    const userWd = width;
    const { pages, app_path } = useGCtx()!
    const filtered_restaurant = pages.filter(info => info.unique === 4)[0];
    const { title, subtitle, items } = filtered_restaurant;

    const clickCard = () => {
        console.log('Card')
    }

    return (
        <section className="onp-restaurants rest" id="restaurants">
            <div className="rest-tble">
                <div className="rest-info">
                    <h3 className="dny-subtitle dny-subtitle--dark">
                        <span className="dny-subtitle-small">{title}</span>
                        {subtitle}
                    </h3>
                    <div className="rest-info-slides">
                        {
                            userWd > breakPoints.desktop ?
                                items.map((item) => {
                                    return (
                                        Object.keys(item).map(key => {
                                            return (
                                                <div className="rest-info-slide-desktop" key={`slide-${key}`}>
                                                    <SlideCard
                                                        path={app_path}
                                                        image={item[key].images}
                                                        alt={item[key].name}
                                                        name={item[key].name}
                                                        priceRange={item[key].price_id}
                                                        category={item[key].category_id}
                                                        callback={clickCard} />
                                                </div>
                                            )
                                        })
                                    )
                                })
                                :
                                <Swiper
                                    slidesPerView='auto'
                                    centeredSlides={true}
                                    spaceBetween={20}
                                    tag="section" id="restaurant-cards">
                                    {
                                        items.map((item) => {
                                            return (
                                                Object.keys(item).map(key => {
                                                    return (
                                                        <SwiperSlide key={`slide-${key}`}>
                                                            <SlideCard
                                                                path={app_path}
                                                                image={item[key].images}
                                                                alt={item[key].name}
                                                                name={item[key].name}
                                                                priceRange={item[key].price_id}
                                                                category={item[key].category_id}
                                                                callback={clickCard} />
                                                        </SwiperSlide>
                                                    )
                                                })
                                            )
                                        })
                                    }
                                </Swiper>
                        }
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Restaurants;
