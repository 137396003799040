import React from 'react'
import { Route, Switch, useLocation } from "react-router-dom";
import { useGCtx } from '../context/providers/GlobalProvider';
import { AnimatePresence } from 'framer-motion';
import PartialLoader from '../components/UI/loading/Partial-loader';
import GlobalLoader from '../components/UI/loading/Global-loading';

// Import Helmet Library for meta tags SEO Aux
import {Helmet} from "react-helmet";



// Pages
import MasterPage from '../components/master-page/Master';
import Onepage from '../pages/Onepage'
import Registration from '../pages/Registration'
import Error from '../pages/Error'

// import Blog from './Blog';
import Construction from './Construction';
import ScrollTop from '../components/UI/ScrolltoTop';

export const App: React.FC = () => {
    const { app_loading, user_firstvisit } = useGCtx()!;
    const location = useLocation();
    const key = location.pathname;

    return (
        <>
            {
                app_loading ?
                    user_firstvisit ?

                        <GlobalLoader />
                        :
                        <PartialLoader />
                    :
                    // Ya se cargo
                    !user_firstvisit ? (
                        <MasterPage>
                            <AnimatePresence exitBeforeEnter>
                                <ScrollTop />
                                <Switch location={location} key={key}>
                                    <Route exact path="/" component={Onepage} />
                                    <Route exact path="/registration" component={Registration} />
                                    <Route exact path="/blog" component={Construction} />
                                    <Route exact path="/error" component={Error} />
                                </Switch>
                            </AnimatePresence>
                        </MasterPage>
                    ) : (
                        <GlobalLoader />
                    )
            }
        </>
    );
}

export default App;
