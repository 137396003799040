import React from 'react'
import { motion } from 'framer-motion';
import { pageCoreography } from '../actions/choreography/pageCoreography';

// Section Components 
import RegistrationForm from '../components/registration/RegistrationForm';
import ProsDinny from '../components/registration/ProsDinny';
import Faqs from '../components/onepage/Faqs';
import VideoDinny from '../components/registration/VideoRegis';
import FeaturesDinny from '../components/registration/FeaturesRegis';

// Styles
import '../styles/pages/page-registration.scss';

export default function Registration() {

    return (
        <motion.section initial="initial"
            animate="in"
            exit="out"
            variants={pageCoreography.homeAnimation}
            className="tmp-registration"
        >
            <RegistrationForm />
            <ProsDinny />
            <VideoDinny />
            <FeaturesDinny />
            <Faqs />
        </motion.section>
    )
}
