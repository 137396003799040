import React, { ReactElement } from 'react';
import { useGCtx } from '../../context/providers/GlobalProvider';
import Tabs from '../UI/tabs/Tabs';
import Tab from '../UI/tabs/Tab';
import TabContent from '../UI/tabs/TabContent';

// Styles
import '../../styles/pages/onepage-bussiness.scss'

const Bussiness = (): ReactElement => {

    const { pages, app_path } = useGCtx()!
    const hiwks = pages.filter(info => info.unique === 5)[0];
    const { title, subtitle, images } = hiwks;

    const loacalimages = images.map((item) => {
        return (
            Object.keys(item).map(key => {

                const cleanKey = item[key]

                return cleanKey
            })
        )
    })
    const img_bussines = loacalimages[0][0];
    const img_dinners = loacalimages[1][0];

    return (
        <section className="onp-bussiness buss" id="bussiness">
            <div className="buss-info">
                <h3 className="dny-subtitle dny-subtitle--white">
                    <span className="dny-subtitle-small">
                        {title}
                    </span>
                    {subtitle}
                </h3>
                <div className="buss-tble">

                    <Tabs>
                        <Tab title="Negocio">
                            <TabContent
                                path={app_path}
                                selector="negocio"
                                image={app_path + img_bussines.filename_disk}
                                alt="Negocio"
                                title="Tus clientes ordenan desde Dinny."
                                list={hiwks.your_business}
                            />
                        </Tab>
                        <Tab title="Cliente">
                            <TabContent
                                path={app_path}
                                selector="cliente"
                                image={app_path + img_dinners.filename_disk}
                                alt="Cliente"
                                title="Descarga la aplicación, olvídate de las filas y disfruta tu comida sin preocupaciones."
                                list={hiwks.diners}
                            />
                        </Tab>
                    </Tabs>
                </div>
            </div>
        </section>
    )
}

export default Bussiness;
