
import React, { ReactElement } from 'react';
import { DinnyMenuObject } from '../../../context/globalContext';

type footerList = {
    title: string;
    links: DinnyMenuObject[]
}

const FooterLists = (props: footerList): ReactElement => {

    const { title, links } = props;

    const footerLinks = links.map((item) => {
        const lnk = Object.keys(item).map(key => {
            const cleanKey = item[key]
            return cleanKey
        })
        return lnk[0]
    })

    return (
        <div className="dny-footer-list">
            <p className="dny-footer-list-title">{title}</p>
            <ul className="dny-footer-lists">
                {
                    footerLinks.map((item, i) => {
                        return (
                            <li className="dny-footer-list-item" key={i}>
                                <a target="_blank" href={item.url} className="dny-footer-list-link">{item.name}</a>
                            </li>
                        )
                    })
                }
            </ul>
        </div>
    )
}

export default FooterLists;
