import React from 'react';

// Icons
import { FaFacebook, FaTwitter, FaInstagram } from 'react-icons/fa';

export const Socials: React.FC = () => {

    return (
        <>
            <nav className="dny-footer-socials">
                <a href="https://www.facebook.com/Dinny-106997164450212" className="dny-footer-socials-link dny-footer-socials-link--facebook">
                    <FaFacebook />
                </a>
                <a href="https://www.facebook.com/Dinny-106997164450212" className="dny-footer-socials-link dny-footer-socials-link--facebook">
                    <FaInstagram />
                </a>
                <a href="https://www.facebook.com/Dinny-106997164450212" className="dny-footer-socials-link dny-footer-socials-link--facebook">
                    <FaTwitter />
                </a>
            </nav>
        </>
    )
}

export default Socials;
