import { motion, useTransform, useViewportScroll } from 'framer-motion'
import React from 'react'
import { homeCoreography } from '../../actions/choreography/homeCoreography'
import { useGCtx } from '../../context/providers/GlobalProvider'

import '../../styles/pages/onepage-namnam.scss'

type propsNamNam = {
    title?: string;
    images?: object[];
}

const Namnam: React.FC<propsNamNam> = (props: propsNamNam) => {
    const { images } = props;

    const localImages = images.map((item) => {
        const img = Object.keys(item).map(key => {
            const cleanKey = item[key]
            return cleanKey
        })
        return img[0]
    }).filter(item => item !== null)

    const { app_path } = useGCtx()!;

    const { scrollY } = useViewportScroll();
    const y2 = useTransform(scrollY, [0, 400], [0, 50]);
    const y3 = useTransform(scrollY, [0, 100], [0, -20]);
    const y4 = useTransform(scrollY, [0, 600], [0, -30]);

    return (
        <figure className="dny-namnam">
            <motion.img
                initial="hidden"
                animate="visible"
                style={{ y: y3 }}
                transition={{
                    delay: .4,
                    duration: .55,
                    type: "tween",
                    ease: "easeInOut",
                }}
                variants={homeCoreography.enterTextTop}
                src={app_path + localImages[1]?.filename_disk} alt={localImages[1]?.name} className="dny-namnam--purple" />
            <motion.img
                initial="hidden"
                animate="visible"
                style={{ y: y4 }}
                transition={{
                    delay: .3,
                    duration: .55,
                    type: "tween",
                    ease: "easeInOut",
                }}
                variants={homeCoreography.enterTextBottom}
                src={app_path + localImages[0]?.filename_disk} alt={localImages[0]?.name} className="dny-namnam--red" />
            <motion.img
                initial="hidden"
                animate="visible"
                transition={{
                    delay: .2,
                    duration: .55,
                    type: "tween",
                    ease: "easeInOut",
                }}
                style={{ y: y2 }}
                variants={homeCoreography.enterTextBottom}
                src={app_path + localImages[2].filename_disk} alt={localImages[2].name} className="dny-namnam--phone" />
        </figure>
    )
}

export default Namnam