export const getPhrase = (string, number, order) => {


    const word = string.replace('.', ' .').split(' ')

    if (number === 2) {
        const matchedSplittedText =
            word[order[0]] + ' ' + word[order[1]] + ' '

        return matchedSplittedText
    } else if (number === 1) {
        const matchedSplittedText =
            word[order[0]] + ' '
        return matchedSplittedText
    }
    else if (number === 3) {
        const matchedSplittedText =
            word[order[0]] + ' ' + word[order[1]] + ' ' + word[order[2]]
        return matchedSplittedText
    }
    else if (number === 4) {
        const matchedSplittedText =
            word[order[0]] + ' ' + word[order[1]] + ' ' + word[order[2]] + ' ' + word[order[3]]
        return matchedSplittedText
    }
    else if (number === 5) {
        const matchedSplittedText =
            word[order[0]] + ' ' + word[order[1]] + ' ' + word[order[2]] + ' ' + word[order[3]] + ' ' + word[order[4]]
        return matchedSplittedText
    }
    else if (number === 6) {
        const matchedSplittedText =
            word[order[0]] + ' ' + word[order[1]] + ' ' + word[order[2]] + ' ' + word[order[3]] + ' ' + word[order[4]] + ' ' + word[order[5]]
        return matchedSplittedText
    }
}