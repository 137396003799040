import React, { ReactElement, useState } from 'react';
import { useGCtx } from '../../context/providers/GlobalProvider';
import { AnimatePresence, motion } from 'framer-motion';
import { homeCoreography } from '../../actions/choreography/homeCoreography';
import YouTube from 'react-youtube';
import { GoPlay } from "react-icons/go";
import { GrClose } from 'react-icons/gr';

//Styles 
import '../../styles/pages/page-registration-video.scss'

const VideoDinny = (): ReactElement => {

    const { registration } = useGCtx()!
    const filtered_video = registration.filter(info => info.unique === 3)[0];
    const { title, subtitle, url } = filtered_video;

    const [show_video, setShowTtip] = useState(false);
    const [show_button, setShowButton] = useState(true);

    const action = () => {
        setShowButton(false)
        setShowTtip(true)
    }

    const actionClose = () => {
        setShowButton(true)
        setShowTtip(false)
    }

    const opts = {
        height: '390',
        width: '640',
        playsinline: 1,
        rel: 0,
        modestbranding: 1,
        controls: 2,
        mute: 1,
        opts: {
            autoplay: 1,
        }
    };


    return (
        <section className="registration-video solution">
            <AnimatePresence exitBeforeEnter>
                {
                    show_button && (
                        <motion.h3
                            initial="hidden"
                            animate="visible"
                            transition={{ delay: .2, duration: .55 }}
                            variants={homeCoreography.enterTextTop}
                            className="dny-subtitle dny-subtitle--white">
                            <motion.span
                                initial="hidden"
                                animate="visible"
                                transition={{ delay: .2, duration: .55 }}
                                className="dny-subtitle-small">
                                {title}
                            </motion.span>
                            {subtitle}
                        </motion.h3>
                    )
                }
            </AnimatePresence>
            <div className="solution-mask">
                <AnimatePresence exitBeforeEnter>
                    {
                        show_button && (
                            <motion.div
                                variants={homeCoreography.fadeIn}
                                initial="hidden"
                                animate="visible"
                                exit="hidden"
                                transition={{
                                    duration: .1,
                                    ease: "easeInOut",
                                    delayChildren: 0.2
                                }}
                                className="solution-mask-button"
                                onClick={action}>
                                <div className="solution-mask-button--icon">
                                    <GoPlay />
                                </div>
                            </motion.div>
                        )
                    }
                </AnimatePresence>
                <AnimatePresence>
                    {
                        show_video && (
                            <motion.div
                                initial="initial"
                                animate="in"
                                exit="out"
                                transition={{ delay: .2, duration: .55 }}
                                variants={homeCoreography.fadeIn}
                                className="solution-video-anim">
                                <div className="solution-video--close" onClick={actionClose}>
                                    <GrClose />
                                </div>
                                <YouTube
                                    opts={opts}
                                    videoId={url}
                                    onEnd={actionClose}                // defaults -> null
                                    id='solution-video'                       // defaults -> null
                                    className='solution-video'                // defaults -> null
                                    containerClassName='solution-video-wrapper'       // defaults -> ''   // defaults -> noop
                                />

                            </motion.div>
                        )
                    }
                </AnimatePresence>
            </div>
        </section>
    )
}

export default VideoDinny;