import React, { ReactElement } from 'react';
import { useGCtx } from '../../context/providers/GlobalProvider';
import HubspotForm from 'react-hubspot-form';

// Styles 
import '../../styles/pages/registration-form.scss'
import { homeCoreography } from '../../actions/choreography/homeCoreography';
import { motion } from 'framer-motion';

const RegistrationForm = (): ReactElement => {

    const { registration } = useGCtx()!
    const filtered_form = registration.filter(info => info.unique === 1)[0];
    const { title, subtitle } = filtered_form;

    return (
        <section className="form-registro" id="registration">
            <div className="form-wrapper regis">
                <div className="regis-info">
                    <motion.p
                        initial="hidden"
                        animate="visible"
                        transition={{ duration: .55 }}
                        variants={homeCoreography.enterText}
                        className="regis-info-presentation">{title}</motion.p>
                    <motion.p
                        initial="hidden"
                        animate="visible"
                        transition={{ delay: .3, duration: .55 }}
                        variants={homeCoreography.enterText}
                        className="regis-info-description">{subtitle}</motion.p>
                </div>
                <div className="regis-form">
                    <HubspotForm
                        portalId='19585437'
                        formId='5f31eaac-caec-4245-aca3-7dfe56a67b2a'
                        onSubmit={() => console.log('Submit!')}
                        onReady={(form) => console.log('Form ready!')}
                        loading={<div> Loading...</div>}
                    />
                </div>
            </div>
        </section>
    )
}

export default RegistrationForm;