import React, { ReactElement } from 'react';
import classNames from "classnames";
import { motion } from 'framer-motion';

// Styles
import '../../../styles/components/UI/buttons.scss'

type buttonData = {
    type: "tab" | "main" | "outlined";
    text: string;
    activeTab?: boolean;
    action: (e: any) => void;
}

const Button = (props: buttonData): ReactElement => {
    const { type, text, action, activeTab } = props;

    const handleClick = (e: any) => {
        if (action) {
            action(e)
        } else {
            console.log('No action has passed')
        }
    }

    return (
        <>
            {
                type === 'main' && (
                    <motion.button
                        whileHover={{ scale: 1.01 }}
                        whileTap={{ scale: 0.9 }}
                        className="dny-btn dny-btn--main" value={text} onClick={handleClick}>
                        {text}
                    </motion.button>
                )
            }
            {
                type === 'outlined' && (
                    <motion.button
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                        className="dny-btn dny-btn--outlined" value={text} onClick={handleClick}>
                        {text}
                    </motion.button>
                )
            }
            {
                type === 'tab' && (
                    <motion.button
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                        className={
                            classNames({
                                'dny-btn': true,
                                'dny-btn--tab': true,
                                'dny-btn--tab-active': activeTab,
                            })
                        } value={text} onClick={handleClick}>
                        {text}
                    </motion.button>
                )
            }
        </>
    )
}

export default Button;