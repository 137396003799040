import React, { useRef } from 'react';
import Header from './Header'
import Footer from './Footer';

// Styles
import '../../styles/components/master-page/glob-page.scss'

type Props = {
    children: React.ReactNode;
};

export const MasterPage = ({ children }: Props) => {
    const squareRef = useRef(null)

    return (
        <>
            <Header />
            <div className="dny-page">
                <div className="dny-page-fluid" ref={squareRef}>
                    {children}
                </div>
            </div>
            <Footer />
        </>
    )
}

export default MasterPage;
