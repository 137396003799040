import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';

// Global provider
import DinnyProvider from './context/providers/GlobalProvider';

// App
import App from './pages/index';

// Styles
import './styles/main.scss'

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <DinnyProvider>
        <App />
      </DinnyProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);