import React, { ReactElement } from 'react';
import Button from '../UI/buttons/Button';
import { motion, useTransform, useViewportScroll } from 'framer-motion';
import { useGCtx } from '../../context/providers/GlobalProvider';

// Styles
import '../../styles/pages/onepage-downloads.scss'

const Downloads = (): ReactElement => {

    const { pages, app_path } = useGCtx()!
    const dloads = pages.filter(info => info.unique === 2)[0];

    const action = () => {
        window.open(dloads.download_button_link, "_blank")
    }
    const { images, download_android, download_ios, image_store_android, image_store_ios } = dloads;

    const loacalimages = images.map((item) => {
        return (
            Object.keys(item).map(key => {

                const cleanKey = item[key]

                return cleanKey
            })
        )
    })
    const namRight = loacalimages[0][0];

    const img_ios = [image_store_ios].map((item) => {
        return (
            Object.keys(item).map(key => {

                const cleanKey = item[key]

                return cleanKey
            })
        )
    })
    const img_android = [image_store_android].map((item) => {
        return (
            Object.keys(item).map(key => {

                const cleanKey = item[key]

                return cleanKey
            })
        )
    })

    // const googleplay = storeImages[0][0];
    const ios = img_ios[0][0];
    const android = img_android[0][0]


    const { scrollY } = useViewportScroll();
    const y2 = useTransform(scrollY, [0, 700], [0, 20])

    return (
        <section className="onp-downloads dloads" id="downloads">
            <div className="dloads-tble">
                <div className="dloads-info">
                    <h3 className="dny-subtitle dny-subtitle--dark">
                        {dloads.title}
                    </h3>
                    <div className="dloads-info-text">
                        <p className="dny-txt dny-txt--italic">
                            {dloads.copy}
                        </p>
                    </div>
                    <nav className="dloads-nav">
                        <Button type="main" text={dloads.download_button_text} action={action} />

                        <div className="dloads-nav-tble">
                            <a href={download_ios} target="_blank" rel="noreferrer" className="dloads-applink dloads-applink--ios">
                                <img src={app_path + ios} alt={app_path} />
                            </a>
                            <a href={download_android} target="_blank" rel="noreferrer" className="dloads-applink dloads-applink--android">
                                <img src={app_path + android} alt={app_path} />
                            </a>
                        </div>
                    </nav>
                </div>
                <motion.figure
                    style={{ y: y2 }}
                    transition={{ delay: .2, duration: .55 }}
                    className="dloads-img">
                    <img src={app_path + namRight.filename_disk} alt={namRight.title} />
                </motion.figure>
            </div>
        </section>
    )
}

export default Downloads;
