import React, { ReactElement, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { tabSlides } from '../../actions/choreography/Tabslides';
import { useGCtx } from '../../context/providers/GlobalProvider';
import Tab from '../UI/tabs/Tab';
import Tabs from '../UI/tabs/Tabs';
import Accordion from '../UI/accordion/Accordion';

// Styles
import '../../styles/pages/onepage-faqs.scss'

const Faqs = (): ReactElement => {
    const [expanded, setExpanded] = useState<false | number>(0);

    const { pages } = useGCtx()!
    const filtered_faqs = pages.filter(info => info.unique === 6)[0];
    const { title, subtitle, establishments, generals } = filtered_faqs;

    return (
        <section className="onp-faqs faqs" id="faqs">
            <div className="faqs-tble">
                <div className="faqs-info">
                    <h3 className="dny-subtitle dny-subtitle--dark">
                        <span className="dny-subtitle-small">{title}</span>
                        {subtitle}
                    </h3>
                    <div className="faqs-info-text">
                        <Tabs>
                            <Tab title="Generales">
                                <AnimatePresence>
                                    <motion.div
                                        initial="hidden"
                                        animate="visible"
                                        exit="exit"
                                        variants={tabSlides.tabSlide}
                                        transition={{
                                            duration: .2,
                                            ease: "easeInOut",
                                        }}>
                                        {
                                            generals.map((item, i) => {
                                                return (
                                                    Object.keys(item).map(key => {
                                                        return (
                                                            <Accordion i={i} info={item[key]} key={i} expanded={expanded} setExpanded={setExpanded} />
                                                        )
                                                    })
                                                )
                                            })
                                        }
                                    </motion.div>
                                </AnimatePresence>
                            </Tab>
                            <Tab title="Negocios">
                                <AnimatePresence>
                                    <motion.div
                                        initial="hidden"
                                        animate="visible"
                                        exit="exit"
                                        variants={tabSlides.tabSlide}
                                        transition={{
                                            duration: .2,
                                            ease: "easeInOut",
                                        }}>
                                        {
                                            establishments.map((item, i) => {
                                                return (
                                                    Object.keys(item).map(key => {
                                                        return (
                                                            <Accordion i={i} info={item[key]} key={i} expanded={expanded} setExpanded={setExpanded} />
                                                        )
                                                    })
                                                )
                                            })
                                        }
                                    </motion.div>
                                </AnimatePresence>
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Faqs;
