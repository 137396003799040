import React, { ReactElement } from 'react';
import { formatPhrase } from '../../../actions/ui/textFormatter';
import { imagesObject } from '../../../context/globalContext';

// Styles
import '../../../styles/components/UI/benefit-card.scss';

type cardsProps = {
    path: string;
    image?: imagesObject[],
    alt?: string,
    description?: String
}

const BenefitCard = (props: cardsProps): ReactElement => {

    const { image, description, path } = props

    const loacalimages = [image].map((item) => {
        return (
            Object.keys(item).map(key => {
                const cleanKey = item[key]
                return cleanKey
            })
        )
    })

    const img = loacalimages[0][0];
    const alt = loacalimages[0][1];

    const first_copy = formatPhrase({
        string: description,
        type: 'strong',
        selector: 'txt-strong',
        words: ['altas comisiones', 'ni cuotas', 'Incremento de pedidos', 'Pick Up', 'Soporte']
    });

    return (
        <article className="benefitcard">
            <figure className="benefitcard-img">
                <img src={path + img} alt={alt} />
            </figure>
            <div className="benefitcard-info">
                <p className="benefitcard-description">
                    {first_copy}
                </p>
            </div>
        </article>
    )
}

export default BenefitCard;
