import React, { useRef } from 'react';
import classNames from "classnames";
import { motion, useCycle } from 'framer-motion';
import { useHistory, useLocation } from 'react-router';
import { useGCtx } from '../../context/providers/GlobalProvider';
import { breakPoints, useCurrentWidth } from '../../actions/ui/windowSize';
import { useDimensions } from '../../actions/use-dimensions';

// Icons
import { GrMenu, GrClose } from "react-icons/gr";

// Styles
import '../../styles/components/master-page/navigation.scss';


const variants = {
    open: {
        transition: {
            staggerChildren: 0.07,
            delayChildren: 0.2
        }
    },
    closed: {
        transition: {
            staggerChildren: 0.05,
            staggerDirection: -1
        }
    }
};

const variantsItem = {
    open: {
        x: 0,
        opacity: 1,
        transition: {
            y: { stiffness: 1000, velocity: -150 }
        }
    },
    closed: {
        x: 50,
        opacity: 0,
        transition: {
            y: { stiffness: 1000 }
        }
    }
};

export const Navigation: React.FC = () => {
    const history = useHistory();
    const location = useLocation();
    const { menu_header } = useGCtx()!;
    const { width } = useCurrentWidth();
    const userWd = width;

    const [isOpen, toggleOpen] = useCycle(false, true);
    const containerRef = useRef(null);
    const { height } = useDimensions(containerRef);

    const navTo = (e: { currentTarget: any; }) => {
        const tolink = e.currentTarget.dataset.to;
        history.push(tolink);
        toggleOpen();
    }

    const openNav = (e: { currentTarget: any; }) => {
        toggleOpen();
    }

    const circlePosition = userWd > breakPoints.mobilewide ? '842px' : '446px'

    const sidebar = {
        open: (height = 1000) => ({
            clipPath: `circle(${height * 2 + 200}px at 40px 40px)`,
            transition: {
                type: "spring",
                stiffness: 20,
                restDelta: 2
            }
        }),
        closed: {
            clipPath: `circle(30px at ${circlePosition} -50px)`,
            transition: {
                delay: 0.2,
                type: "spring",
                stiffness: 400,
                damping: 40
            }
        }
    };

    return (
        <>
            <nav className={classNames({
                'dny-nav': true
            })}>

                {
                    userWd > breakPoints.desktop ?
                        <motion.div className="dny-nav-desktop" initial={false}
                            animate={isOpen ? "open" : "closed"}
                            custom={height}
                            ref={containerRef}>
                            <ul className="dny-nav-list">
                                {
                                    menu_header.map((item) => {
                                        return (
                                            Object.keys(item).map(key => {
                                                return (
                                                    <li className={classNames({
                                                        "dny-nav-item": true,
                                                        "dny-nav-item--current": location.pathname === item[key].url
                                                    })} key={key} data-to={item[key].url} onClick={navTo}>
                                                        <span>
                                                            {item[key].name}
                                                        </span>
                                                    </li>
                                                )
                                            })
                                        )
                                    })
                                }
                            </ul>
                        </motion.div>
                        :
                        <motion.div className="dny-nav-mobile"
                            initial={false}
                            animate={isOpen ? "open" : "closed"}
                            custom={height}
                            ref={containerRef}
                        >
                            <div className={classNames({
                                'dny-nav-button': true,
                                'dny-nav-button--open': isOpen
                            })} onClick={openNav}>
                                {isOpen ?
                                    <GrClose />
                                    :
                                    <GrMenu />
                                }
                            </div>

                            <aside className={classNames({
                                'dny-nav-mobilewrapper': true,
                                'dny-nav-mobilewrapper--open': isOpen
                            })}>
                                <motion.div className="background" variants={sidebar} />
                                <motion.ul variants={variants} className="dny-nav-list">
                                    {
                                        menu_header.map((item) => {
                                            return (
                                                Object.keys(item).map(key => {
                                                    return (
                                                        <motion.li variants={variantsItem} className={classNames({
                                                            "dny-nav-item": true,
                                                            "dny-nav-item--current": location.pathname === item[key].url
                                                        })} key={key} data-to={item[key].url} onClick={navTo}>
                                                            <span>
                                                                {item[key].name}
                                                            </span>
                                                        </motion.li>
                                                    )
                                                })
                                            )
                                        })
                                    }
                                </motion.ul>
                            </aside>
                        </motion.div>
                }
            </nav>
        </>
    )
}

export default Navigation;
