import React, { ReactElement } from 'react';
import { useGCtx } from '../../context/providers/GlobalProvider';
import { breakPoints, useCurrentWidth } from '../../actions/ui/windowSize';
import { Swiper, SwiperSlide } from 'swiper/react';
import BenefitCard from '../UI/cards/benefit';

//Styles 
import '../../styles/pages/registration-pros.scss'
import 'swiper/swiper.scss';

const ProsDinny = (): ReactElement => {

    const { registration, app_loading, app_path } = useGCtx()!
    const filtered_registration = registration.filter(info => info.unique === 2)[0];
    const { title, subtitle, copy, items } = filtered_registration;

    const { width } = useCurrentWidth();
    const userWd = width;

    return (
        <section className="registration-pros prosdny">
            {!app_loading && (
                <div className="prosdny-wrapper">
                    <div className="prosdny-info">
                        <h3 className="title-txt">
                            {title}
                        </h3>
                        <h2 className="title-main">
                            {subtitle}
                        </h2>
                        <p className="title-description">
                            {copy}
                        </p>
                    </div>
                    <div className="prosdny-benefit">
                        {
                            userWd > breakPoints.mobile ?
                                <div className="prosdny-benefit-desktop">
                                    {
                                        items.map((item) => {
                                            return (
                                                Object.keys(item).map(key => {
                                                    return (

                                                        <div className="prosdny-benefit-item" key={`slide-${key}`}>
                                                            <BenefitCard
                                                                path={app_path}
                                                                image={item[key].image}
                                                                alt={item[key].copy}
                                                                description={item[key].copy}
                                                            />
                                                        </div>

                                                    )
                                                })
                                            )
                                        })
                                    }
                                </div>
                                :
                                <Swiper
                                    slidesPerView={1}
                                    spaceBetween={30}
                                    tag="section" id="restaurant-cards">
                                    {
                                        items.map((item) => {
                                            return (
                                                Object.keys(item).map(key => {
                                                    return (

                                                        <SwiperSlide key={`slide-${key}`}>
                                                            <BenefitCard
                                                                path={app_path}
                                                                image={item[key].image}
                                                                alt={item[key].copy}
                                                                description={item[key].copy}
                                                            />
                                                        </SwiperSlide>

                                                    )
                                                })
                                            )
                                        })
                                    }
                                </Swiper>
                        }
                    </div>
                </div>

            )}
        </section>
    )
}

export default ProsDinny;