import React, { ReactElement, useState } from "react"
import classNames from "classnames";
import TabTitle from "./TabsTitle";

import '../../../styles/components/tabs/tabs.scss'
import { AnimatePresence } from "framer-motion";

type Props = {
    children: ReactElement[]
}

const Tabs: React.FC<Props> = ({ children }) => {
    const [selectedTab, setSelectedTab] = useState(0);
    const leftIndicator = selectedTab === 0;
    const rightIndicator = selectedTab === 1;

    return (
        <>
            <nav className={classNames({
                'tabs-nav': true,
                'tabs-nav--right': rightIndicator,
                'tabs-nav--left': leftIndicator
            })}>
                <ul className="tabs-nav-list">
                    {children.map((item, index) => (
                        <TabTitle
                            key={index}
                            title={item.props.title}
                            index={index}
                            activeTab={selectedTab}
                            setSelectedTab={setSelectedTab}
                        />
                    ))}
                </ul>
            </nav>
            <article className="tabs-content">
                <AnimatePresence exitBeforeEnter initial={false}>
                    {selectedTab === 0 && (children[0])}
                </AnimatePresence>
                <AnimatePresence>
                    {selectedTab === 1 && (children[1])}
                </AnimatePresence>
            </article>
        </>
    )
}

export default Tabs