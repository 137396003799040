export const pageCoreography = {
    homeAnimation: {
        initial: {
            opacity: 1,
            x: 0
        },
        in: {
            opacity: 1,
            x: 0
        },
        out: {
            opacity: 1,
            x: 0
        },
    },
    ConstructionAnimation: {
        initial: {
            opacity: 0,
            transition: {
                type: "tween",
                ease: "easeInOut",
                delay: .5,
                duration: 0.5
            }
        },
        in: {
            opacity: 1,
            transition: {
                type: "tween",
                ease: "easeInOut",
                delay: .5,
                duration: 0.5
            }
        },
        out: {
            opacity: 0,
            transition: {
                type: "tween",
                ease: "easeInOut",
                delay: .5,
                duration: 0.5
            }
        },
    }
}