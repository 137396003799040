import React, { ReactElement } from 'react';
import Button from '../UI/buttons/Button';
import { useGCtx } from '../../context/providers/GlobalProvider';
import { motion, useTransform, useViewportScroll } from 'framer-motion';
import { homeCoreography } from '../../actions/choreography/homeCoreography';
import { breakPoints, useCurrentWidth } from '../../actions/ui/windowSize';
import Namnam from '../UI/namnam';

// Styles
import '../../styles/pages/onepage-home.scss'
import { getPhrase } from '../../actions/ui/getPhrase';

const Home = (): ReactElement => {

    const { pages } = useGCtx()!
    const filtered_home = pages.filter(info => info.unique === 1)[0];

    const action = () => {
        console.log('Hello')
    }

    const { scrollY } = useViewportScroll();
    const y2 = useTransform(scrollY, [0, 300], [0, -50]);

    const { width } = useCurrentWidth();
    const userWd = width;

    const firstTitleLine = getPhrase(filtered_home.title, 1, [0]);
    const secondTitleLine = getPhrase(filtered_home.title, 2, [1, 2]);

    const firstCopyLine = getPhrase(filtered_home.copy, 6, [0, 1, 2, 3, 4, 5]);
    const secondCopyLine = getPhrase(filtered_home.copy, 5, [6, 7, 8, 9, 10]);

    const thirdCopyLine = getPhrase(filtered_home.copy, 5, [13, 14, 15, 16, 17]);
    const fourthCopyLine = getPhrase(filtered_home.copy, 6, [18, 19, 20, 21, 22, 23]);
    const fiftCopyLine = getPhrase(filtered_home.copy, 5, [24, 25, 26, 27, 28]);

    const firstText = firstCopyLine + ' ' + secondCopyLine + '.';
    const copyText = thirdCopyLine + ' ' + fourthCopyLine + ' ' + fiftCopyLine;
    const images = filtered_home.images.map((item) => {
        return (
            Object.keys(item).map(key => {
                return item[key]
            })
        )
    })

    // Fix problem with phrases by words, separate copy by sentences.
    const [firstSentence, secondSentence, thirdSentence] = filtered_home.copy.split('.')
    const firstParagraph = firstSentence + '.'
    const secondParagraph = secondSentence + '.'
    const thirdParagraph = thirdSentence + '.'

    return (
        <section className="onp-home home" id="home">
            <div className="home-info">
                <motion.h2
                    style={{ y: y2 }}
                    transition={{ delay: .2, duration: .55 }}
                    className="dny-title dny-title--big">
                    <motion.div
                        initial="hidden"
                        animate="visible"
                        transition={{ delay: .2, duration: .55 }}
                        variants={homeCoreography.enterText}
                    >
                        {firstTitleLine}
                    </motion.div>
                    <motion.div
                        initial="hidden"
                        animate="visible"
                        transition={{ delay: .3, duration: .55 }}
                        variants={homeCoreography.enterText}
                    >
                        {secondTitleLine}
                    </motion.div>
                </motion.h2>

                <div className="home-info-text">
                    <motion.p
                        initial="hidden"
                        animate="visible"
                        transition={{ delay: .4, duration: .55 }}
                        variants={homeCoreography.enterText}
                        className="dny-txt dny-txt--highlight">
                        {firstParagraph}
                    </motion.p>
                    <motion.p
                        initial="hidden"
                        animate="visible"
                        transition={{ delay: .6, duration: .75 }}
                        variants={homeCoreography.enterText}
                        className="dny-txt dny-txt--highlight pb-0">
                        {secondParagraph}
                    </motion.p>
                    <motion.p
                        initial="hidden"
                        animate="visible"
                        transition={{ delay: .6, duration: .75 }}
                        variants={homeCoreography.enterText}
                        className="dny-txt dny-txt--highlight">
                        {thirdParagraph}
                    </motion.p>
                </div>
                <nav className="home-nav">
                    <Button type="main" text={filtered_home.texto_enlace} action={action} />
                </nav>
                {/* // Si es desktop */}
                {
                    userWd > breakPoints.desktop && (
                        images && (
                            <Namnam
                                images={images}
                            />
                        )
                    )
                }
            </div>
        </section>
    )
}

export default Home;
