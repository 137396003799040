import { useState, useEffect } from 'react'

const getWidth = () => window.innerWidth
    || document.documentElement.clientWidth
    || document.body.clientWidth;

const getHeight = () => window.innerHeight
    || document.documentElement.clientHeight
    || document.body.clientHeight;

export const breakPoints = {
    mobilewide: 460,
    mobile: 767,
    tablet: 1022,
    desktop: 1023,
    bigDesktop: 1440
}

export const useCurrentWidth = () => {
    let [width, setWidth] = useState(getWidth());
    let [height, setHeight] = useState(getHeight());

    useEffect(() => {

        let timeoutId = null;
        const resizeListener = () => {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => {
                setWidth(getWidth())
                setHeight(getHeight())
            }, 150);
        };
        // set resize listener
        window.addEventListener('resize', resizeListener);

        return () => {
            // remove resize listener
            window.removeEventListener('resize', resizeListener);
        }
    }, [])

    return { width, height };
}
