import { motion } from 'framer-motion'
import React from 'react'
import { tabSlides } from '../../../actions/choreography/Tabslides'
import { hiwksParentObject } from '../../../context/globalContext'

import '../../../styles/components/tabs/tab.scss'

type Props = {
    path: string;
    selector: string;
    image: string;
    title: string;
    alt: string;
    list: hiwksParentObject[];
}

const TabContent: React.FC<Props> = ({ selector, image, title, alt, list, path }) => {
    return (
        <motion.div
            initial="hidden"
            animate="visible"
            exit="exit"
            variants={tabSlides.tabSlide}
            transition={{
                duration: .2,
                ease: "easeInOut",
            }}
            className={`tab-content-table tab-content-table--${selector}`}>
            <figure className={`tab-image tab-image--${selector}`}>
                <img src={image} alt={alt} />
            </figure>
            <div className="tab-content-info">
                <h4 className="dny-subsubtitle">
                    <div className="tab-list-icon"></div>
                    <div className="tab-list-bullet-container">
                        <div className="tab-list-bullet"></div>
                    </div>
                    <span className="dny-subsubtitle-data">{title}</span>
                </h4>
                <ul className="tab-list">
                    {
                        list.map((item) => {
                            return (
                                Object.keys(item).map(key => {
                                    return (
                                        <li className="tab-list-item" key={key}>
                                            <span className="tab-list-icon">
                                                <img src={path + item[key].icon.filename_disk} alt={item[key].subtitle} />
                                            </span>
                                            <span className="tab-list-bullet-container">
                                                <span className="tab-list-bullet"></span>
                                            </span>
                                            <span className="tab-list-info">
                                                <p className="tab-list-title">{item[key].title}</p>
                                                <p className="tab-list-text">{item[key].subtitle}</p>
                                            </span>
                                        </li>
                                    )
                                })
                            )
                        })
                    }
                </ul>
            </div>
        </motion.div>
    )
}

export default TabContent;