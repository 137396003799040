import React from 'react';
import { motion, AnimatePresence } from "framer-motion";
import classNames from "classnames";

// Icons
import { FaPlus, FaMinus } from "react-icons/fa";

// Styles
import '../../../styles/components/UI/accordion.scss'

const Accordion = ({ i, info, expanded, setExpanded }) => {
    const isOpen = i === expanded;
    return (
        <>
            <motion.header
                initial={false}
                // animate={{ backgroundColor: isOpen ? "#FF0088" : "#0055FF" }}
                onClick={() => setExpanded(isOpen ? false : i)}
                className={classNames({
                    'accordion-tab': true,
                    'accordion-tab--open': isOpen,
                    'accordion-tab--closed': !isOpen
                })}
            >
                <p className="accordion-question">
                    {info.question}
                </p>
                <span className="accordion-question-icon">
                    {isOpen ?
                        <FaMinus />
                        :
                        <FaPlus />
                    }
                </span>
            </motion.header>
            <AnimatePresence initial={false}>
                {isOpen && (
                    <motion.section
                        key="content"
                        initial="collapsed"
                        animate="open"
                        exit="collapsed"
                        variants={{
                            open: { opacity: 1, height: "auto" },
                            collapsed: { opacity: 0, height: 0 }
                        }}
                        transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
                    >
                        <p className="accordion-answer">
                            {info.answer}
                        </p>
                    </motion.section>
                )}
            </AnimatePresence>
        </>
    );
};

export default Accordion;
