export const loaderCoreography = {
    loadAnimation: {
        initial: {
            opacity: 1,
            y: 0
        },
        in: {
            opacity: 1,
            y: 0
        },
        out: {
            opacity: 1,
            y: -100
        },
    },
    dinoAnimation: {
        hidden: {
            origin: 'center',
            opacity: 0,
            y: 100
        },
        visible: {
            opacity: 1,
            y: 0
        }
    },
    drinkAnimation: {
        hidden: { opacity: 0, originX: 'bottom left', rotate: 18 },
        visible: { opacity: 1, originX: 'bottom left', rotate: -2 },
        out: {
            opacity: 0,
        },
    },
    orangeDrinkAnimation: {
        hidden: {
            origin: 'center bottom',
            scale: 1,
            y: 0,
            x: 0
        },
        visible: {
            scale: 0.3,
            y: 20,
            x: 30
        },
        out: {
            opacity: 0,
        },
    },
    logoSquare: {
        hidden: {
            opacity: 0,
            origin: 'center',
            scale: .0
        },
        visible: {
            opacity: 1,
            scale: 1
        }
    },
    logoText: {
        hidden: {
            opacity: 0,
            x: -100
        },
        visible: {
            opacity: 1,
            x: 0
        }
    },
    screenBlink: {
        hidden: {
            opacity: .6,
        },
        visible: {
            opacity: 1,
        }
    },
}