import React, { ReactElement } from 'react';

import '../../../styles/components/master-page/footer-sign.scss';

interface signProps {
    path: string;
    img: string;
}

const FooterSignature = (props: signProps): ReactElement => {
    const { path, img } = props;

    return (
        <div className="dny-footer-sign">
            <p className="dny-txt">Tecnología desarrollada con <img src={path + img} alt="Amor" /> por <span className="dny-txt--strong">IA interactive<sup>®</sup></span>.</p>
        </div>
    )
}

export default FooterSignature;
