import React from 'react'
import { pageCoreography } from '../actions/choreography/pageCoreography';
import { motion } from 'framer-motion';

// Sections
import Home from '../components/onepage/Home';
import Partners from '../components/onepage/Partners';
import Downloads from '../components/onepage/Downloads';
import Restaurants from '../components/onepage/Restaurants';
import Bussiness from '../components/onepage/howToBussines';
import Faqs from '../components/onepage/Faqs';
import {Helmet} from "react-helmet";

export default function Onepage() {
    return (
        <motion.div
            initial="initial"
            animate="in"
            exit="out"
            variants={pageCoreography.homeAnimation} className="tmp--onepage">
            
            {/* // Open Graph Protocol & Twitter Card for SEO */}
            <Helmet> // SEO - Open Graph & Twitter Card
                <meta property="og:type" content="website"/>
                <meta property="og:title" content="Dinny | Solución digital para la operación de tu restaurante."/>
                <meta property="og:url" content="https://dinnyapp.com"/>
                <meta property="og:description" content="Dinny es una plataforma digital que opera tu negocio con tecnología contactlees. Tus clientes conocerán el menú del restaurante al escanear un código QR."/>
                <meta property="og:image" content="https://dinny-dev-static-cdn-iazbxr.s3.amazonaws.com/landing/opengraph.jpg"/>
                <meta name="twitter:card" content="summary"/>
                <meta name="twitter:description" content="Dinny es una plataforma digital que opera tu negocio con tecnología contactlees. Tus clientes conocerán el menú del restaurante al escanear un código QR."/>
                <meta name="twitter:title" content="Dinny | Solución digital para la operación de tu restaurante."/>
                <meta name="twitter:site" content="https://dinnyapp.com"/>
                <meta name="twitter:image" content="https://dinny-dev-static-cdn-iazbxr.s3.amazonaws.com/landing/opengraph.jpg"/>
                <meta charSet="utf-8" />
            </Helmet>

            {/* // Home */}
            <Home />

            {/* // Downloads */}
            <Downloads />

            {/* // Partners */}
            <Partners />

            {/* // Restaurants */}
            <Restaurants />

            {/* // Bussiness */}
            <Bussiness />

            {/* // Bussiness */}
            <Faqs />
        </motion.div>
    )
}
