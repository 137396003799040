import React from "react";

function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
}

export const formatPhrase = ({ string, type, selector, words }) => {
    const modifiedWords = words.reduce(
        (prev, word) => {
            const newWords = [];
            const reg = new RegExp(escapeRegExp(word), "gi");
            let index;

            prev.forEach((e) => {
                // Only match for element which is string, if it is not string,
                // it's already processed(like span or something)
                if (typeof e === "string") {
                    const wordLength = word.length;
                    let matched = false;

                    do {
                        const { index } = reg.exec(e) || {};

                        // Keep matching till no more matches found
                        if (index !== undefined) {
                            newWords.push(e.substr(0, index));

                            if (type === 'strong') {
                                newWords.push(
                                    React.createElement(
                                        'strong',
                                        {
                                            key: `position-${newWords.length}-${index}`,
                                            className: selector
                                        },
                                        e.substr(index, wordLength)
                                    )
                                );
                            } else if (type === 'span') {
                                newWords.push(
                                    React.createElement(
                                        'span',
                                        {
                                            key: `position-${newWords.length}-${index}`,
                                            className: selector,
                                            value: `action-${newWords.length}-${index}`
                                        },
                                        e.substr(index, wordLength)
                                    )
                                );
                            } else if (type === 'super') {
                                newWords.push(
                                    React.createElement(
                                        'sup',
                                        {
                                            key: `position-${newWords.length}-${index}`,
                                            className: selector
                                        },
                                        e.substr(index, wordLength)
                                    )
                                );
                            }
                            // You can also directly use span here instead of React.createElement
                            // newWords.push(<span>{e.substr(index, wordLength)}</span>);
                            newWords.push(e.substr(index + wordLength));
                            matched = true;
                        }
                    } while (index);

                    // If word is not matched, push original sentence
                    if (!matched) {
                        newWords.push(e);
                    }
                } else {
                    // Push processed element as it is
                    newWords.push(e);
                }
            });

            return newWords;
        },
        [string]
    );

    return modifiedWords
}