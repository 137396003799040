import React, { ReactElement } from 'react';
import { imagesObject, refsCatObject, refsPriceObject } from '../../../context/globalContext';
// import CardLabel from './label';

// Styles
import '../../../styles/components/UI/cards.scss';

type cardsProps = {
    path: string;
    image: imagesObject[],
    alt?: string,
    name?: string,
    priceRange?: refsPriceObject[],
    category?: refsCatObject[],
    callback: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const SlideCard = (props: cardsProps): ReactElement => {
    const { path, image, alt, name, priceRange, category, callback } = props

    const loacalimages = image.map((item) => {
        return (
            Object.keys(item).map(key => {
                const cleanKey = item[key]
                return cleanKey
            })
        )
    })

    const img = loacalimages[0][0]

    const localPrice = [priceRange].map((item) => {
        return (
            Object.keys(item).map(key => {
                const cleanKey = item[key]
                return cleanKey
            })
        )
    })

    const prices = localPrice[0][0]

    const localCat = [category].map((item) => {
        return (
            Object.keys(item).map(key => {
                const cleanKey = item[key]
                return cleanKey
            })
        )
    })

    const cats = localCat[0][0]

    return (
        <article className="card" onClick={callback}>
            <figure className="card-img">
                <img src={path + img.filename_disk} alt={alt} />
            </figure>
            <div className="card-info">
                <h4 className="dny-subsubtitle">{name}</h4>
                <p className="card-category">
                    <span className="dny-txt">{cats}</span>
                    -
                    <span className="dny-txt">{prices}</span>
                </p>
                {/* <CardLabel category={cats} /> */}
            </div>
        </article>
    )
}

export default SlideCard;
