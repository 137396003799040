import React, { ReactElement } from 'react';
import Button from '../UI/buttons/Button';
import { useGCtx } from '../../context/providers/GlobalProvider';

// Styles
import '../../styles/pages/onepage-partners.scss'

const Partners = (): ReactElement => {
    const { pages, app_path } = useGCtx()!
    const partners = pages.filter(info => info.unique === 3)[0];
    const { title, copy, download_button_text, images } = partners;

    const loacalimages = images.map((item) => {
        return (
            Object.keys(item).map(key => {

                const cleanKey = item[key]

                return cleanKey
            })
        )
    })
    const namRight = loacalimages[0][0];

    const action = () => {
        console.log('Hello')
    }

    return (
        <section className="onp-partners parn" id="partners">
            <div className="parn-tble">
                <div className="parn-info">
                    <h3 className="dny-subtitle dny-subtitle--white">
                        {title}
                    </h3>
                    <div className="parn-info-text">
                        <p className="dny-txt dny-txt--italic">
                            {copy}
                        </p>
                    </div>
                    <nav className="parn-nav">
                        <Button type="main" text={download_button_text} action={action} />
                    </nav>
                </div>
                <figure className="parn-img">
                    <img src={app_path + namRight.filename_disk} alt={namRight.title} />
                </figure>
            </div>
        </section>
    )
}

export default Partners;
