
import React from 'react'
import { formatPhrase } from '../../actions/ui/textFormatter';
import { refsObject } from '../../context/globalContext';

import '../../styles/components/UI/list-item.scss'



type propsList = {
    title?: string;
    images?: object[];
    data?: refsObject[];
    index?: number;
}

const FListItem: React.FC<propsList> = (props: propsList) => {

    const { data } = props;



    const localPrice = [data].map((item) => {
        return (
            Object.keys(item).map(key => {
                const cleanKey = item[key]
                return cleanKey
            })
        )
    })


    const formatItem = localPrice[0][1];

    const first_copy = formatPhrase({
        string: formatItem,
        type: 'strong',
        selector: 'txt-strong',
        words: ['medidas sanitarias Covid-19', 'punto de venta', 'Experiencia 360º', 'disminuye costos', 'métricas', 'Gestiona rápidamente']
    });

    return (
        <li className="features-list-item">{first_copy}</li>
    )
}

export default FListItem