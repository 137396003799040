import React, { useEffect, useState } from 'react';
import { motion, useTransform, useViewportScroll } from 'framer-motion';
import { useLocation } from 'react-router';
import classNames from "classnames";
import Navigation from './Navigation';

// Styles
import '../../styles/components/master-page/header.scss'
import { useGCtx } from '../../context/providers/GlobalProvider';
import { homeCoreography } from '../../actions/choreography/homeCoreography';

export const Header: React.FC = () => {
    const { scrollY } = useViewportScroll();
    const y2 = useTransform(scrollY, [0, 300], [0, -150]);
    const location = useLocation();

    const [isComplete, setIsComplete] = useState(false);
    const yRange = useTransform(scrollY, [0, 300], [0, 150]);

    useEffect(() => yRange.onChange(v => setIsComplete(v >= 35)), [yRange]);

    const { app_path } = useGCtx()!;
    const logoVer = app_path + 'bbb62298-2084-4d0d-9106-9a0fc0bbd19c.svg'
    const logoHor = app_path + 'e4aea915-fab3-4b10-b501-d88790d6d71e.svg'


    return (
        <header className={classNames({
            'dny-header': true,
            'dny-header--down': isComplete,
            'dny-header--global': location.pathname !== "/"
        })}>
            <div className="dny-header-panel">
                <div className="dny-header-logo">
                    {
                        location.pathname === "/" ? (
                            <>
                                <motion.figure className="dny-logo"
                                    style={{ y: y2 }}
                                    initial="hidden"
                                    animate="visible"
                                    transition={{ delay: .2, duration: .55 }}
                                    variants={homeCoreography.fadeInTop}
                                >
                                    <img src={logoVer} alt="Dinny logo" />
                                </motion.figure>
                                {isComplete && (
                                    <figure className="dny-logo--hor">
                                        <img src={logoHor} alt="Dinny logo" />
                                    </figure>
                                )}
                            </>
                        ) :
                            <figure className="dny-logo--hor">
                                <img src={logoHor} alt="Dinny logo" />
                            </figure>
                    }
                </div>
                <Navigation />
            </div>
        </header>
    )
}

export default Header;
