import axios from 'axios';
const graphPath = 'https://directus.dinnyapp.com/'
/*
  Dev env = https://dev-directus.dinnyapp.com/
  Prod env = https://directus.dinnyapp.com/
*/


export const fetchOnepage = async () => {
  const dataHome = {
    query: `
      query {
        home {
          id,
          title,
          copy,
          texto_enlace,
          url_enlace,
          images {
            directus_files_id {
              filename_disk,
              title
            }
          }
        }
        download {
          id,
          title,
          copy,
          download_button_text,
          download_button_link,
          background,
          download_ios,
          download_android,
          image_store_ios {
               filename_disk,
               title
          },
          image_store_android {
               filename_disk,
               title
          }
          images{
           directus_files_id {
               filename_disk,
               title
           }
          }
        }
        partner {
          id,
          title,
          copy,
          background,
          download_button_text,
          download_button_link,
          images {
            directus_files_id {
              filename_disk,
              title
            }
          }
        }
        references {
          id, 
          title, 
          subtitle, 
          items {
            references_items_id {
              name,
              category_id {
                name
              },
              price_id {
                description
              },
              images {
                directus_files_id {
                  filename_disk,
                  title
                }
              }
            }
          }
        }
        how_it_work { 
          id, 
          title,
          subtitle,
          background, 
          images {
              directus_files_id {
                  filename_disk,
                  title
              }
          },
          your_business { 
              how_it_work_items_id {
              id, 
              title, 
              subtitle, 
              icon { 
                  filename_disk,
                  title 
              } 
              }
          }, 
          diners { 
              how_it_work_items_id { 
              id,
              title,
              subtitle, 
              icon { 
                  filename_disk,
                  title
              }
              } 
          } 
        }
        section_faqs {
          id,
          title,
          subtitle,
          background,
          generals {
            faqs_id {
              id,
              question,
              answer
            }
          },
          establishments {
            faqs_id {
              id,
              question,
              answer
            }
          }
        }
      }
    `
  }

  try {
    const responseHome = await axios({
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer qwertyuiopasdfghjklzxcvbnm`,
      },
      data: dataHome,
      url: graphPath + 'graphql'
    });

    const {
      home,
      download,
      partner,
      references,
      how_it_work,
      section_faqs
    } = responseHome.data.data;

    const onepageData = [
      { unique: 1, ...home[0] },
      { unique: 2, ...download[0] },
      { unique: 3, ...partner[0] },
      { unique: 4, ...references[0] },
      { unique: 5, ...how_it_work[0] },
      { unique: 6, ...section_faqs[0] }
    ]

    return onepageData

  } catch (error) {
    console.error(error);
  }
}

export const fetchMaster = async () => {
  const dataMaster = {
    query: `
      query {
        home {
            header {
                links_id {
                    id,
                    name,
                    url
                }
            },
            footer {
                links_id {
                    id,
                    name,
                    url
                }
            }
        }
      }
    `
  }

  try {
    const responseMaster = await axios({
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer qwertyuiopasdfghjklzxcvbnm`,
      },
      data: dataMaster,
      url: graphPath + 'graphql'
    });

    const { home } = responseMaster.data.data;

    const masterResponse = home[0];


    if (masterResponse) {
      return masterResponse
    }

  } catch (error) {
    console.error(error);
  }
}