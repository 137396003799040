import React from 'react';
import { useGCtx } from '../../context/providers/GlobalProvider';
import FooterSignature from '../UI/footer/Footer-signature';
import FooterLogo from '../UI/footer/Footer-logo';
import Socials from '../../components/master-page/Socials';
import FooterLists from '../UI/footer/Footer-list';

// Icons

// Styles
import '../../styles/components/master-page/footer.scss'

export const Footer: React.FC = () => {
    const { pages, menu_footer, app_path } = useGCtx()!;
    const dloads = pages.filter(info => info.unique === 2)[0];

    const { download_android, download_ios, image_store_android, image_store_ios } = dloads;

    const img_ios = [image_store_ios].map((item) => {
        return (
            Object.keys(item).map(key => {

                const cleanKey = item[key]

                return cleanKey
            })
        )
    })
    const img_android = [image_store_android].map((item) => {
        return (
            Object.keys(item).map(key => {

                const cleanKey = item[key]

                return cleanKey
            })
        )
    })

    // const googleplay = storeImages[0][0];
    const ios = img_ios[0][0];
    const android = img_android[0][0]

    const col1 = {
        title: "Información de interés",
    }

    const col2 = {
        title: "Escríbenos",
    }

    const linksCol = [menu_footer[0], menu_footer[1], menu_footer[2]]
    const linksColR = [menu_footer[3]]

    return (
        <>
            <footer className="dny-footer">
                <FooterSignature img="b9609f35-d51e-4034-a3b9-8d6cbcbcf2a3.svg" path={app_path} />

                <div className="dny-footer-table">
                    <FooterLogo />

                    <div className="dny-footer-info-desktp">
                        <div className="dny-footer-info">
                            <FooterLists title={col1.title} links={linksCol} />
                            <FooterLists title={col2.title} links={linksColR} />
                            <Socials />
                        </div>
                        <div className="dloads-nav-tble">
                            <a href={download_ios} target="_blank" rel="noreferrer" className="dloads-applink dloads-applink--ios">
                                <img src={app_path + ios} alt={app_path} />
                            </a>
                            <a href={download_android} target="_blank" rel="noreferrer" className="dloads-applink dloads-applink--android">
                                <img src={app_path + android} alt={app_path} />
                            </a>
                        </div>
                    </div>
                </div>
                <div className="dny-footer-separator">
                    <hr></hr>
                </div>
                <div className="dny-footer-disclaimer">
                    <div className="dny-footer-disclaimer-table">
                        <div className="dny-footer-disclaimer--left">
                            <p className="dny-txt dny-txt--strong">©2021 Dinny</p>
                            <p className="dny-txt">Todos los derechos reservados.</p>
                        </div>
                        <div className="dny-footer-disclaimer--simples">
                            <p className="dny-txt--softgray">reCAPTCHA y Google protegen este sitio. Se aplica la Política de privacidad y los Términos del servicio.</p>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer;
