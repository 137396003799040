export const homeCoreography = {
    fadeInTop: {
        hidden: { opacity: 0, y: -100 },
        visible: { opacity: 1, y: 0 },
        out: {
            opacity: 0
        }
    },
    fadeIn: {
        visible: {
            opacity: 1,
            x: 0,
        },
        hidden: {
            opacity: 0,
            x: -20
        }
    },
    enterText: {
        hidden: {
            opacity: 0,
            x: 40
        },
        visible: {
            opacity: 1,
            x: 0
        }
    },
    enterTextTop: {
        hidden: {
            opacity: 0,
            y: -800
        },
        visible: {
            opacity: 1,
            y: 0
        }
    },
    enterTextBottom: {
        hidden: {
            opacity: 0,
            ease: "easeIn",
            y: 800
        },
        visible: {
            opacity: 1,
            y: 0
        }
    }
}