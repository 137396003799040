import axios from 'axios';
const graphPath = 'https://directus.dinnyapp.com/'

export const fetchRegistration = async () => {
    const dataSlider = {
        query: `
            query {
                register_slider {
                    id,
                    title,
                    subtitle,
                    images {
                        directus_files_id {
                            filename_disk,
                            title
                        }
                    }
                }
                integral_solution {
                    id,
                    title,
                    subtitle,
                    copy,
                    background,
                    items {
                        integral_solution_items_id {
                            copy
                            image {
                                filename_disk,
                                title
                            }
                        }
                    }
                }
                video {
                    id,
                    title,
                    subtitle,
                    url,
                    file {
                        filename_disk,
                        title
                    }
                }
                features {
                    id,
                    title,
                    subtitle,
                    download_button_text,
                    download_button_link,
                    background,
                    image {
                            filename_disk,
                        title
                    },
                    items {
                        feature_items_id {
                            id,
                            description
                        }
                    }
                }
            }
        `
    }

    try {
        const responseSlides = await axios({
            method: 'POST',
            headers: {
                'content-type': 'application/json',
                Authorization: `Bearer qwertyuiopasdfghjklzxcvbnm`,
            },
            data: dataSlider,
            url: graphPath + 'graphql'
        });

        const { register_slider, integral_solution, video, features } = responseSlides.data.data;


        const registrationData = [
            { unique: 1, ...register_slider[0] },
            { unique: 2, ...integral_solution[0] },
            { unique: 3, ...video[0] },
            { unique: 4, ...features[0] },
        ]

        return registrationData

    } catch (error) {
        console.error(error);
    }
}


