import React, { ReactElement } from 'react';
import { useGCtx } from '../../context/providers/GlobalProvider';
import { breakPoints, useCurrentWidth } from '../../actions/ui/windowSize';
import ListItem from '../UI/List';
import Button from '../UI/buttons/Button';


//Styles 
import '../../styles/pages/page-registration-features.scss'

const FeaturesDinny = (): ReactElement => {

    const { registration, app_path } = useGCtx()!
    const filtered_features = registration.filter(info => info.unique === 4)[0];
    const { title, subtitle, items, image, download_button_text } = filtered_features;

    const localImage = [image].map((item) => {
        return (
            Object.keys(item).map(key => {
                const cleanKey = item[key]
                return cleanKey
            })
        )
    })

    const imagePath = localImage[0][0]
    const altPath = localImage[0][1];

    const { width } = useCurrentWidth();
    const userWd = width;

    const action = () => {
        console.log(download_button_text)
    }

    return (
        <section className="registration-features features">
            <div className="features-layout">
                <div className="features-bubbles">
                    <h3
                        className="dny-subtitle dny-subtitle--dark">
                        <span
                            className="dny-subtitle-small">
                            {title}
                        </span>
                        {subtitle}
                    </h3>
                    {
                        userWd > breakPoints.desktop && (
                            <Button type="main" text={download_button_text} action={action} />
                        )
                    }
                </div>
                <div className="features-flex">
                    <ul className="features-list">
                        {
                            items.map((item, i) => {
                                return (
                                    Object.keys(item).map(key => {
                                        return (
                                            <ListItem key={i} data={item[key]} index={i} />
                                        )
                                    })
                                )
                            })
                        }
                    </ul>
                    <div className="features-parallax">
                        <img src={app_path + imagePath} alt={altPath} />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default FeaturesDinny;