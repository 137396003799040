import React, { createContext, FC, useContext, useEffect, useState } from "react";
import useLocalStorage from "../../actions/localStorage";
import { fetchMaster, fetchOnepage } from "../../API/API-onepage";
import { fetchRegistration } from "../../API/API-registration";
import { DinnyContextState, DinnyMenuObject, onepageObject } from "../globalContext";

const path = 'https://static-landing.dinnyapp.com/landing/media/'

/*
    Resources Path
    Dev env = https://dev-static-landing.dinnyapp.com/landing/media/
    Production env = https://static-landing.dinnyapp.com/landing/media/
*/  


const contextDefaultValues: DinnyContextState = {
    pages: [],
    registration: [],
    menu_header: [],
    menu_footer: [],
    app_path: path,
    app_loading: true,
    user_firstvisit: true,
    user_camefromlogo: true,
    setLoading: () => { },
    setUserVisit: () => { },
    setUserCamefromlogoVisit: () => { }
};

export const DinnyContext = createContext<DinnyContextState>(
    contextDefaultValues
);

const DinnyProvider: FC = ({ children }) => {
    const [pages, setPages] = useState<onepageObject[]>(contextDefaultValues.pages);
    const [registration, setRegistration] = useState<onepageObject[]>(contextDefaultValues.registration);
    const app_path = path;
    const [app_loading, setLoading] = useState(contextDefaultValues.app_loading);
    const [user_firstvisit, setUserVisit] = useLocalStorage('usr-firstvisit', true)
    const [user_camefromlogo, setUserCamefromlogoVisit] = useLocalStorage('usr-camefromlogo', true)

    const [menu_header, setHeaderMenu] = useState<DinnyMenuObject[]>(contextDefaultValues.menu_header);
    const [menu_footer, setFooterMenu] = useState<DinnyMenuObject[]>(contextDefaultValues.menu_footer);


    const getMaster = async () => {
        // Pages Information
        const masterData = await fetchMaster();
        const { header, footer } = masterData;

        setHeaderMenu(header);
        setFooterMenu(footer);
    }

    const getData = async () => {
        // Pages Information
        const homeData = await fetchOnepage();
        const masterData = await fetchMaster();
        const { header, footer } = masterData;
        const registrationData = await fetchRegistration();

        setHeaderMenu(header);
        setFooterMenu(footer);
        setRegistration(registrationData)
        setPages(homeData);

        setTimeout(() => {
            setLoading(false)
        }, 1000)
    }

    const globalValues = {
        pages,
        registration,
        menu_header,
        menu_footer,
        app_path,
        app_loading,
        setLoading,
        setRegistration,
        user_firstvisit,
        setUserVisit,
        user_camefromlogo,
        setUserCamefromlogoVisit
    }

    useEffect(() => {
        getMaster();
        getData();
    }, []);

    return (
        <div className="app" data-theme='root'>
            <DinnyContext.Provider
                value={globalValues}
            >
                {children}
            </DinnyContext.Provider>
        </div>
    );
};

export default DinnyProvider;

export const useGCtx = () => useContext(DinnyContext);
