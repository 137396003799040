import React, { ReactElement } from 'react';

const FooterLogo = (): ReactElement => {

    return (
        <figure className="dny-logo--ver">
            <img src="./images/img-logo-txt-hor.svg" alt="Dinny logo" />
        </figure>
    )
}

export default FooterLogo;
